label {
  font-weight: bold;
  display: flex;
  margin-bottom: 5px;
}

/* input[type='text'],
  input[type='email'],
  textarea {
    display: block;
    width: 400px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
  }  */

.textFieldFormik {
  display: block;
  width: 475px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-controlFormik {
  display: block;
  margin-bottom: 20px;
  padding: 6px 12px;
  font-size: 14px;
  background-color: #20374c;
  color: #fff;
  width: 500px;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-controlFormikTextArea {
  display: block;
  margin-bottom: 20px;
  padding: 6px 12px;
  font-size: 14px;
  background-color: #20374c;
  color: #fff;
  width: 500px;
  height: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

label {
  font-weight: bold;
  display: flex;
  margin-bottom: 5px;
}

input[type='text'],
input[type='email'],
input[type='password'],
textarea,
select {
  display: block;
  width: 470px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  /* margin-bottom: 20px; */
}

.form-control {
  margin-bottom: 20px;
}

.error {
  color: red;
}

.nav-item-text {
  color: rgb(255, 255, 255);
}

.color-gold {
  color: rgb(165, 113, 72);
}

.nav-item-text-gold {
  color: rgb(165, 113, 72);
  font-size: 24px;
}

.nav-item-text-dark {
  color: rgb(0, 0, 0);
  font-size: 24px;
}

.nav-item-text-dark-md {
  color: rgb(0, 0, 0);
  font-size: 12px;
}

.nav-item-dropdown {
  background-color: rgb(69, 69, 69);
  color: rgb(255, 255, 255);
}

.container {
  max-width: 100%;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.card-header-text {
  color: rgb(161, 122, 91);
}

.card-content-text {
  color: rgb(170, 170, 150);
}
